export const translations = [
  {
    PL: "Co możemy dla Ciebie zrobić?",
    DE: "Was können wir für Sie tun?",
    FR: "Que pouvons-nous faire pour vous ?",
    EN: "What can we do for you?",
    IT: "Cosa possiamo fare per voi?",
  },
  {
    PL: "kontakt",
    DE: "kontakt",
    FR: "contact",
    EN: "contact",
    IT: "contatto",
  },
  {
    PL: "DANE KONTAKTOWE: ",
    DE: "Kontaktdaten:",
    FR: "CONTACT : ",
    EN: "CONTACT DETAILS",
    IT: "I DATI DI CONTATTO:",
  },
  {
    PL: "Adres: ",
    DE: "Adresse:",
    FR: "Adresse ",
    EN: "Address:",
    IT: "Indirizzo:",
  },
  {
    PL: "NIP: ",
    DE: "Steueridentifikationsnummer",
    FR: "PNM (NIP)",
    EN: "Tax Indentification Number (NIP):",
    IT: "Numero di identificazione fiscale (NIP):",
  },
  {
    PL: "Sekretariat:",
    DE: "Sekretariat:",
    FR: "Secrétariat :",
    EN: "Main office:",
    IT: "Segreteria:",
  },
  {
    PL: "tel:",
    DE: "Tel.:",
    FR: "Tél :",
    EN: "tel:",
    IT: "tel:",
  },
  {
    PL: "Zespół Obsługi Klienta:",
    DE: "Kundenservice-Team:",
    FR: "Customer Service Team - Service clients:",
    EN: "Customer Service Department:",
    IT: "Servizio Clienti:",
  },
  {
    PL: "współpraca: ",
    DE: "Zusammenarbeit:",
    FR: "Collaboration : ",
    EN: "collaboration:",
    IT: "Collaborazione:",
  },
  {
    PL: "tel.wew:",
    DE: "Durchwahl:",
    FR: "tel.ext:",
    EN: "internal tel.:",
    IT: "tel.ext:",
  },
  {
    PL: "tel.kom:",
    DE: "Mobile:",
    FR: "tel.mobile:+48",
    EN: "mobile:",
    IT: "mobile:",
  },
  {
    PL: "Nowe uruchomienia, zapytania:",
    DE: "Neue Produkte, Anfragen:",
    FR: "Nouveaux lancements, demandes d' informations:",
    EN: "New projects, inquiries:",
    IT: "Nuovi progetti, richieste:",
  },
  {
    PL: "Kadry:",
    DE: "PersonalAbteilung",
    FR: "Ressources humaines :",
    EN: "Personal Department:",
    IT: "Risorse umane:",
  },
  {
    PL: "Zgłoszenie nieprawidłowości:",
    DE: "Mitteilung von Unregelmäßigkeiten:",
    FR: "Signalement d'irrégularités :",
    EN: "Report irregularity:",
    IT: "Segnalazione di non conformità",
  },
  {
    PL: "Formularz nieprawidłowości (.doc)",
    DE: "Formular für Unregelmäßigkeiten (.doc)",
    FR: "Formulaire d'irrégularité (.doc)",
    EN: "Irregularity Report (.doc)",
    IT: "Modulo di non conformità",
  },
  {
    PL: "Linki do pobrania:",
    DE: "Download-Links:",
    FR: "Liens à télécharger :",
    EN: "Download:",
    IT: "Link per il download:",
  },
  {
    PL: "Serwis i naprawa elektroniki:",
    DE: "Service:",
    FR: "Service et réparation de l'électronique :",
    EN: "Service and Repair of Electronics:",
    IT: "Servizio e riparazione degli elettronica:",
  },
];
